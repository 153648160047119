import "./chatBotMoga.css";
import moga from "../../assets/png/moga.png";
import mualiimg from "../../assets/home2/Body/muali.png"

const ChatMessage = ({ text, isUser }) => {
  return (
    <div className={`message-main ${isUser ? 'sender':''}`}>
     { !isUser && <img loading="lazy" src={mualiimg} alt="Logo" className="messageLogo" />}
      <p className={`message-text ${isUser ? 'sender':''}`} dangerouslySetInnerHTML={{__html:text}}/>
    </div>
  );
};

export default ChatMessage;
