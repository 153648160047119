import React from 'react'

import { Container, Row, Col, Table } from 'react-bootstrap';
import { dateData } from './Schedulejson';
import styles from "./SchedulePage.module.css";
import dayjs from "dayjs";

const DateSchedule = ({ dateArray, startIndex, visibleDates }) => {
    console.log("dateArray", dateArray)
    return (
        <Container fluid className="overiew_margin_top py-2">
            <Row>
                <Col lg={2} className="center_title">
                    <h5 className="sprots_Discipline_title">Sports /Discipline</h5>
                </Col>
                <Col lg={10}>
                    <Row>
                        <Col lg={7}>
                            <div>
                                <ul className="d-flex justify-content-between list_style_none month_wrapper_container mt-2">
                                    <li><h4>OCTOBER</h4></li>
                                    <li><h4>NOVEMBER</h4></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <ul className="d-flex w-100 sport_Date_list_wrap justify-content-between overiew_calender_container">
                        {dateArray
                            .slice(startIndex, startIndex + visibleDates)
                            .map((item, id) => (
                                <div key={id + "-calendar"}>
                                    <>
                                        <li>
                                            <h3>{dayjs(item)?.format("DD")}</h3>
                                            <p>{dayjs(item)?.format("ddd")}</p>
                                        </li>
                                    </>
                                    
                                </div>
                            ))}

                    </ul>
                </Col>
            </Row>

        </Container>
    )
}

export default DateSchedule