export const sportsTiles = [
  "Archery.png",
  "Athletics.png",
  "Badminton.png",
  "Basketball.png",
  "Boxing.png",
  "Cycling.png",
  "Equestrian.png",
  "Fencing.png",
  "Football.png",
  "Golf.png",
  "Gymnastics.png",
  "Handball.png",
  "Hockey.png",
  "Judo.png",
  "Kabaddi.png",
  // "Karate.png",
  // "Kayaking and Canoeing.png",
  "Kho-Kho.png",
  "Lawn ball.png",
  // "Mallakhamb.png",
  "Modern Pentathlon.png",
  "Netball.png",
  "Rowing.png",
   "Rugby Sevens.png",
   "Sailing.png",
   "Shooting.png",
   "Squash.png",
   "Table Tennis.png",
   "Taekwondo.png",
  //  "Tennis.png",
   "Triathlon.png",
   "Volleyball.png",
   "Weightlifting.png",
   "Wrestling.png",
   "Wushu.png",
  //  "Yogasana.png"
  
];

export const differentSportsResults = [
  "mini-golf",
  "shooting",
  "modern-pentathlon-",
  "modern-pentathlon",
  "taekwondo",
];

export const statesTiles = {
  "Andaman and Nicobar Islands": "/assets/Color/ANDAMAN-NICOBAR-ISLANDS_01.png",
  "Dadra and Nagar Haveli & Daman and Diu":
    "/assets/Color/ANDAMAN-NICOBAR-ISLANDS_01.png",
  "Andhara Pradesh": "/assets/Color/Andhra Pradesh.png",
  "Andhra Pradesh": "/assets/Color/Andhra Pradesh.png",
  "Arunachal Pradesh": "/assets/Color/ARUNACHAL-PRADESH_01.png",
  Assam: "/assets/Color/ASSAM_01.png",
  Bihar: "/assets/Color/Bihar.png",
  Chhattisgarh: "/assets/Color/Chhattisgarh.png",
  Chandigarh: "/assets/Color/HARYANA_01.png",
  Delhi: "/assets/Color/DELHI_01.png",
  Goa: "/assets/Color/Goa.png",
  Gujarat: "/assets/Color/Gujarat.png",
  Haryana: "/assets/Color/HARYANA_01.png",
  "Himachal Pradesh": "/assets/Color/HIMACHAL-PRADESH_01.png",
  "Jammu and Kashmir": "/assets/Color/JAMMU-KASHMIR_01.png",
  "Jammu And Kashmir": "/assets/Color/JAMMU-KASHMIR_01.png",
  "Jammu & Kashmir": "/assets/Color/JAMMU-KASHMIR_01.png",
  "J & K": "/assets/Color/JAMMU-KASHMIR_01.png",
  Ladakh: "/assets/Color/JAMMU-KASHMIR_01.png",
  Jharkand: "/assets/Color/JHARKAND_01.png",
  Jharkhand: "/assets/Color/JHARKAND_01.png",
  Karnataka: "/assets/Color/Karnataka.png",
  Kerala: "/assets/Color/Kerala.png",
  Lakshadweep: "/assets/Color/LAKSHADWEEP_01.png",
  "Madhya Pradesh": "/assets/Color/Madhya Pradesh.png",
  Maharashtra: "/assets/Color/Maharashtra.png",
  Manipur: "/assets/Color/MANIPUR_01.png",
  Meghalaya: "/assets/Color/MEGHALAYA_01.png",
  Mizoram: "/assets/Color/MIZORAM_01.png",
  Nagaland: "/assets/Color/NAGALAND_01.png",
  Orissa: "/assets/Color/ORISSA_01.png",
  Odisha: "/assets/Color/ORISSA_01.png",
  Punjab: "/assets/Color/Punjab.png",
  Puducherry: "/assets/Color/MEGHALAYA_01.png",
  Rajasthan: "/assets/Color/RAJASTHAN_01.png",
  Sikkim: "/assets/Color/SIKKIM_01.png",
  "Tamil Nadu": "/assets/Color/TAMIL-NADU_01.png",
  Telangana: "/assets/Color/Telangana.png",
  Tripura: "/assets/Color/TRIPURA_01.png",
  "Uttar Pradesh": "/assets/Color/Uttar Pradesh.png",
  Uttarakhand: "/assets/Color/Uttarakhand.png",
  "West Bengal": "/assets/Color/West Bengal.png",
  "Services Sports Control Board": "/assets/Color/SSCBLOGO.jpg",
  Sscb: "/assets/Color/SSCBLOGO.jpg",
  sscb: "/assets/Color/SSCBLOGO.jpg",
};
export const bannerTiles = {
  aquatics: "/assets/images/Sports-Banner/Aquatics.png",
  archery: "/assets/images/Sports-Banner/Archery.png",
  athletics: "/assets/images/Sports-Banner/Athletics.png",
  badminton: "/assets/images/Sports-Banner/badminton",
};

export const leagueTiles = {
  "Gujrat": "/assets/league/gujrat.png",
  "Jammu and Kashmir": "/assets/league/jammuKashmirpng",
  "Rajasthan": "/assets/league/rajasthan.png",
  "Uttar Pradesh": "/assets/league/uttarpradesh.png",
  "Panjab": "/assets/league/Punjab.png",
  "Kerala": "/assets/league/Kerala.png",
  "Tamil Nadu": "/assets/league/Tamil.png",
  "Services Sports Control Board": "/assets/league/sb.png",
};

export const stateIds = {
  "andaman-and-nicobar-islands": "1",
  "andhra-pradesh": "2",
  "arunachal-pradesh": "3",
  assam: "4",
  bihar: "5",
  chandigarh: "6",
  chhattisgarh: "7",
  "dadra-and-nagar-haveli-and-daman-and-diu": "8",
  delhi: "10",
  goa: "11",
  gujarat: "12",
  haryana: "13",
  "himachal-pradesh": "14",
  "jammu-and-kashmir": "15",
  jharkhand: "16",
  karnataka: "17",
  kerala: "18",
  lakshadweep: "19",
  "madhya-pradesh": "20",
  maharashtra: "21",
  manipur: "22",
  meghalaya: "23",
  mizoram: "24",
  nagaland: "25",
  odisha: "26",
  puducherry: "27",
  punjab: "28",
  rajasthan: "29",
  sikkim: "30",
  "tamil-nadu": "31",
  tripura: "32",
  "uttar-pradesh": "33",
  uttarakhand: "34",
  "west-bengal": "35",
  telangana: "36",
  ladakh: "37",
  "services-sports-control-board": "38",
};
export const sportsName = [
  "All Games",
  "Football",
  "Hockey",
  "Kabaddi",
  "Kho-kho",
  "Weightlifting",
  "Badminton",
  "Basketball",
  "Handball",
  "Volleyball",
  "Table Tennis",
  "Gatka",
  "Boxing",
  "Taekwondo",
  "Wushu",
  "Judo",
  "Cycling",
  "Golf",
  "Squash",
  "Lawn Bowl",
  "Fencing",
  "Rowing",
  "Beach Handball",
  "Beach Volleyball",
  "Rollball",
  "Netball",
  "Lagori",
  "Rugby",
  "Triathlon",
  "Sqay Martial Arts",
  "Beach Soccer",
  "Mallakhamb",
  "Gymnastics",
  "Mini Golf",
  "Yogasana",
  "Wrestling",
  "Lawn Tennis",
  "Pencak Silat",
  "Sepak Takraw",
  "Billiards and Snooker",
  "Canoeing & Kayaking",
  "Athletics",
  "Kalaripayattu",
  "Aquatics",
  "Yachting",
  "Archery",
  "Shooting",
  "Modern Pentathlon",
  "Beach Soccer",
];

export const sportsNameWithRouting = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Badminton",
    value: "badminton",
    routing: "badminton",
  },
  {
    label: "Netball",
    value: "netball",
    routing: "netball",
  },
  {
    label: "Basketball",
    value: "Basketball",
    routing: "basketball",
  },
  {
    label: "Gymnastics",
    value: "gymnastic",
    routing: "gymnasium",
  },
  {
    label: "Rugby",
    value: "Rugby",
    routing: "rugby",
  },
  {
    label: "Weightlifting",
    value: "weightlifting",
    routing: "weightlifting",
  },
  {
    label: "Mallakhamb",
    value: "mallakhamb",
    routing: "mallakhamb",
  },
  {
    label: "Pencak Silat",
    value: "Pencak-Silat",
    routing: "pencak-silat",
  },
  {
    label: "Modern Pentathlon",
    value: "Modern-Pentathlon",
    routing:
      "https://tsr.37nationalgamesgoa.in/scoring-scheduling/sport/modern_pentathlon",
  },
  {
    label: "Fencing",
    value: "fencing",
    routing: "fencing",
  },
  {
    label: "Billiards & Snookers",
    value: "billiard-and-snooker",
    routing: "billiard-&-snooker",
  },
  {
    label: "Billiards and Snooker",
    value: "billiard-and-snooker",
    routing: "billiard-&-snooker",
  },
  {
    label: "Football (Women)",
    value: "football",
    routing: "football",
  },
  {
    label: "Football",
    value: "football",
    routing: "football",
  },
  {
    label: "Rowing",
    value: "rowing",
    routing: "rowing",
  },
  {
    label: "Beach Football",
    value: "football",
    routing: "beach-football",
  },
  {
    label: "Table Tennis",
    value: "table-tennis",
    routing: "table-tennis",
  },
  {
    label: "Athletics",
    value: "Athletics",
    routing: "athletics",
  },
  {
    label: "Aquatics",
    value: "Aquatics",
    routing: "aquatics",
  },
  {
    label: "Archery",
    value: "Archery",
    routing: "archery",
  },
  {
    label: "Rollball",
    value: "roll-ball",
    routing: "rollball",
  },
  // {
  //   label: "Sepak Takraw",
  //   value: "sepak-takraw",
  //   routing: "sepak-takraw",
  // },
  {
    label: "Lawn Tennis",
    value: "tennis",
    routing: "lawn-tennis",
  },
  {
    label: "Hockey",
    value: "hockey",
    routing: "hockey",
  },
  {
    label: "Football (Men)",
    value: "football",
    routing: "football",
  },
  {
    label: "Beach Soccer",
    value: "football",
    routing: "beach_soccer",
  },
  {
    label: "Gatka",
    value: "gatka",
    routing: "gatka",
  },
  {
    label: "Taekwondo",
    value: "taekwondo",
    routing:
      "https://tsr.37nationalgamesgoa.in/scoring-scheduling/sport/taekwondo",
  },
  {
    label: "Mini Golf",
    value: "golf",
    routing:
      "https://tsr.37nationalgamesgoa.in/scoring-scheduling/sport/mini_golf",
  },
  {
    label: "Wrestling",
    value: "wrestling",
    routing: "wrestling",
  },
  {
    label: "Wushu",
    value: "wushu",
    routing: "wushu",
  },
  {
    label: "Volleyball",
    value: "Volleyball-_-Beach-Volleybal",
    routing: "volleyball",
  },
  {
    label: "Squash",
    value: "squash",
    routing: "squash",
  },
  {
    label: "Lawn Bowls",
    value: "lawn-bowl",
    routing: "lawn-bowl",
  },
  {
    label: "Lawn Bowl",
    value: "lawn-bowl",
    routing: "lawn-bowl",
  },
  {
    label: "Boxing",
    value: "boxing",
    routing: "boxing",
  },
  {
    label: "Cycling (Track Race)",
    value: "cycling",
    routing: "cycling",
  },
  {
    label: "Shooting",
    value: "shooting",
    routing:
      "https://tsr.37nationalgamesgoa.in/scoring-scheduling/sport/shooting",
  },
  {
    label: "Rowing (Coastal/Tidal Sport)",
    value: "rowing",
    routing: "rowing",
  },
  {
    label: "Yachting",
    value: "yachting",
    routing: "yachting",
  },
  {
    label: "Triathlon",
    value: "Trithlon",
    routing: "triathlon",
  },
  {
    label: "Canoeing & Kayaking",
    value: "Canoeing-_-Kayaking",
    routing: "canoeing-&-kayaking",
  },
  {
    label: "Beach Volleyball",
    value: "Volleyball-_-Beach-Volleybal",
    routing: "beach-volleyball",
  },
  {
    label: "Handball",
    value: "handball-and-beach-handball",
    routing: "handball",
  },
  {
    label: "Kabaddi",
    value: "kabaddi",
    routing: "kabaddi",
  },
  {
    label: "Kho-Kho",
    value: "kho-kho",
    routing: "kho-kho",
  },
  {
    label: "Kho-Kho",
    value: "kho-kho",
    routing: "kho-kho",
  },
  {
    label: "Lagori",
    value: "lagori",
    routing: "lagori",
  },
  {
    label: "Beach Handball",
    value: "handball-and-beach-handball",
    routing: "beach-handball",
  },
  {
    label: "Golf",
    value: "golf",
    routing: "golf",
  },
  {
    label: "Yogasana",
    value: "yoga",
    routing: "yogasana",
  },
  {
    label: "Sqay Martial Arts",
    value: "sqay-martial-art",
    routing: "sqay-martial-arts",
  },
  {
    label: "Judo",
    value: "judo",
    routing: "judo",
  },
  {
    label: "Kalaripayattu",
    value: "kallaripayattu",
    routing: "kalaripayattu",
  },
  {
    label: "Cycling (Road Race)",
    value: "cycling",
    routing: "cycling",
  },
  {
    label: "Cycling",
    value: "cycling",
    routing: "cycling",
  },
];
