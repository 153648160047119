import React from 'react'

const CommonSelectDropdwn = ({ListData}) => {
    return (
        <>
            <select name="cars" id="cars" className="select_drop_dwn px-1 py-2">
                {ListData.map((data) => {
                    return (
                        <>
                         <option value={data.value}  key={data.id}>{data.name}</option>
                        </>
                    )
                })}
               
               

            </select>
        </>
    )
}

export default CommonSelectDropdwn