import { useEffect } from "react";
import { useLocation } from "react-router-dom";
function ScrollTopTop({ children }) {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const isElimination =
    pathSegments[pathSegments.length - 1] === "elimination";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return  <div style={{ paddingTop: isElimination ? "8%" : "0%" }}>{children}</div>;
}

export default ScrollTopTop;
