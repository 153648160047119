import LiveTimer from "components/common/liveTimer";
import "./footer.css";
import { useState } from "react";
import ChatBotMoga from "components/chatBotMoga";
import mualiimg from "../../assets/home2/Body/muali.png"

const Footerbottom = () => {
  const [openChatBox, setOpenChatBox] = useState(false);

  return (
    <div
      className=""
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 1001,
        right:'50px'
      }}
    >
      <div className="footer-body">
        {/* <LiveTimer /> */}
        {/* <div
          className="brand"
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://khelotech.com", "_blank")}
        >
          Powered by Khelo Tech
        </div> */}
        <div
          className="chat-box"
          style={{ cursor: "pointer" }}
          onClick={() => setOpenChatBox(!openChatBox)}
        >
          <img
            loading="lazy"
            src={mualiimg}
            alt="Chatbot"
            className="chatbot-img"
          />
        </div>
      </div>
      {openChatBox && <ChatBotMoga setOpenChatBox={setOpenChatBox} />}
    </div>
  );
};

export default Footerbottom;
