import "./chatBotMoga.css";
import ChatBotHeader from "./chatBotHeader";
import ChatMessage from "./chatMessage";
import { useEffect, useRef, useState } from "react";
import {
  chatbotOptions,
  decisionMaking,
  questionAnswer,
} from "components/common/constant";
import moga from "../../assets/png/moga.png";
import mualiimg from "../../assets/home2/Body/muali.png"


const Conversation = ({ userName, setOpenChatBox }) => {
  const messagesEndRef = useRef(null);
  const [conversationList, setConversationList] = useState([]);

  const handleSelectOptions = (data, selectedOption) => {
    //add the selected option to chat
    if (chatbotOptions.includes(data)) {
      let contactData = [];
      // if (data === "Contact Us") {
      //   contactData = [
      //     {
      //       text: `For any sort of assistance kindly Call on: 08061115103 and for specific queries kindly contact the respective APO’s`,
      //       isUser: false,
      //     },
      //   ];
      // }
      // for the explore goa
      if (data === "Explore Goa") {
        setConversationList([
          ...conversationList,
          { text: `${data}`, isUser: true },
          ...contactData,
          {
            text: `<p>On India's southwest coast is the state of Goa. It is one of the most wellliked tourist sites in the nation and is renowned for its stunning beaches,
           vibrant culture, and extensive history.
           <a
           class="inner-link"
           href="https://goa-tourism.com" 
           target="_blank">
           https://goa-tourism.com
           </a></br></p></p>`,
            isUser: false,
          },
        ]);
      } else {
        setConversationList([
          ...conversationList,
          { text: `${data}`, isUser: true },
          ...contactData,
          {
            options: getQuestions(data, questionAnswer),
            isUser: false,
            selectedOption: data,
          },
        ]);
      }
    }

    //geting single question answer and adding to the chat
    if (data && selectedOption) {
      setConversationList([
        ...conversationList,
        {
          text: data,
          isUser: true,
        },
        {
          text: getSingleQuestion(data, selectedOption, questionAnswer),
          isUser: false,
        },
        {
          text: `Would you like to continue?`,
          isUser: false,
        },
        {
          options: decisionMaking,
          isUser: false,
        },
      ]);
    }

    if (decisionMaking.includes(data)) {
      setConversationList([
        ...conversationList,
        {
          text: data,
          isUser: true,
        },
      ]);

      if (data === "Yes") {
        setConversationList([
          ...conversationList,
          {
            options: chatbotOptions,
            isUser: false,
          },
        ]);
      } else {
        setConversationList([
          ...conversationList,
          {
            text: "Thanks for interaction with Moga",
            isUser: false,
          },
        ]);
        setOpenChatBox(false);
      }
    }
  };

  const getQuestions = (option, questionandAnswer) => {
    console.log("questionandAnswer",questionandAnswer);
    console.log("questionandAnsweroption",option);
    return questionandAnswer
      .find((item) => item.section === option)
      .questions.map((question) => question.question);
  };

  const getSingleQuestion = (question, selectedOption, questionandAnswer) => {
    return questionandAnswer
      .find((item) => item.section === selectedOption)
      .questions.find((ques) => ques.question === question).answer;
  };

  const capitalizeFirstLetter = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (userName) {
      setConversationList([
        { text: `Hello ${capitalizeFirstLetter(userName)}`, isUser: false },
        {
          text: `Welcome to the 38th National Games, Uttarakhand! How can I assist you today? `,
          isUser: false,
        },
        { options: chatbotOptions, isUser: false },
      ]);
    }
  }, [userName]);

  useEffect(() => {
    scrollToBottom();
  }, [conversationList]);

  return (
    <div className="Conversation-main">
      <ChatBotHeader
        setOpenChatBox={setOpenChatBox}
      />
      <div className="Conversation-body" id="conversation-body">
        {conversationList?.map((item, index) => {
          return (
            <div ref={messagesEndRef}>
              {item?.text && (
                <ChatMessage
                  text={item.text}
                  isUser={item.isUser}
                  key={index}
                />
              )}
              {item?.options && (
                <div className="option-main">
                  <img
                    loading="lazy"
                    src={mualiimg}
                    alt="Logo"
                    className="messageLogo"
                  />
                  <div className="option-body">
                    {item?.options?.map((option, index) => (
                      <p
                        key={index}
                        className="chat-option"
                        onClick={() => {
                          handleSelectOptions(option, item?.selectedOption);
                        }}
                      >
                        {option}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Conversation;
