import React from "react";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";

const PageTitle = ({
  motherMenu,
  activeMenu,
  pageContent,
  Button,
  fetchData = () => {},
}) => {
  //   let path = window.location.pathname.split("/");
  let path = window.location.pathname
    .split("/")
    .filter((p) => p)
    .join("/");
  return (
    <div className="row page-titles mx-0 page_title_wrap">
    <div className="d-flex align-items-center">
      <ol className="breadcrumb me-auto">
        <li className=" active">
          <span><RiArrowRightSLine className="arrow_right_icon" /></span>
          <Link to={`/${path}`} className="page_Title_text mt-2">{motherMenu}</Link>
        </li>
        
        {/* Conditionally render the activeMenu breadcrumb item */}
        {activeMenu && (
          <li className="breadcrumb-item">
            <span><RiArrowRightSLine className="arrow_right_icon" /></span>
            <Link to={`/${path}`} className="page_Title_text mt-2">{activeMenu}</Link>
          </li>
        )}
      </ol>
      
      {Button && <Button fetchData={fetchData} />}
    </div>
  </div>
  );
};

export default PageTitle;
