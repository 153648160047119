import React from 'react'
import ScheduleAndResults from ".";
import { Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import "./style.css";
import CommonSelectDropdwn from './CommonSelectDropdwn';
import ClusterTable from './ClusterTable';

export const getPropertyNoCase = (obj, prop) => {
  return obj[
    Object.keys(obj)?.find((key) =>
      prop?.toLowerCase()?.includes(key.toLowerCase())
    )
  ];
};

const SportList = [
  {
    id: 1,
    name: "SPORT",
    value: "sport"
  },
  {
    id: 2,
    name: "Cycling",
    value: "Cycling"
  },
  {
    id: 3,
    name: "Golf",
    value: "Golf"
  },
]

const GenderList = [
  {
    id: 1,
    name: "GENDER",
    value: "gender"
  },
  {
    id: 2,
    name: "Male",
    value: "male"
  },
  {
    id: 3,
    name: "Female",
    value: "female"
  },
]

const ClusterList = [
  {
    id: 1,
    name: "CLUSTER",
    value: "cluster"
  },
  {
    id: 2,
    name: "Dehradun",
    value: "Dehradun"
  },
  {
    id: 3,
    name: "Haridwar",
    value: "Haridwar"
  },
  {
    id: 4,
    name: "Tehri",
    value: "Tehri"
  },
  {
    id: 5,
    name: "Rishikesh",
    value: "Rishikesh"
  },
  {
    id: 6,
    name: "Rudrapur",
    value: "Rudrapur"
  },
  {
    id: 7,
    name: "Haldwani",
    value: "Haldwani"
  },
  {
    id: 8,
    name: "Nainital",
    value: "Nainital"
  },
 
]

const SchedulePage = () => {
  return (
    <>
      <ScheduleAndResults>

        <Row className="p-5 calender_margin_top">
          <Col lg={6}>
            <div className="d-flex">
              <div >
                <CommonSelectDropdwn ListData={SportList} />
              </div>
              <div className="mx-3">
                <CommonSelectDropdwn ListData={GenderList} />
              </div>
            </div>

          </Col>

          <Col lg={6} className="d-flex justify-content-end">
            <div>
              <CommonSelectDropdwn ListData={ClusterList} />
            </div>
          </Col>
        </Row>

        <ClusterTable/>

      </ScheduleAndResults>
    </>
  )
}

export default SchedulePage