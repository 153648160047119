import { useHistory } from "react-router-dom";
import nationalGamesLogo from "../../../assets/home2/header/uttrakhand 1.png"; 


import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NavLogo = ({ click }) => {
  const history = useHistory();
  // const navigate = useNavigate()

  const handleRedirect = () => {
    window.location.href = "http://usp.38nationalgamesuttarakhand.in/";
  };
  return (
    <div
      style={{ zIndex: 1100 }}
      className="navbar-brands cursor-pointer"
      onClick={() => {
        click();
        history.push("/");
      }}
    >
      <div style={{ padding: "0.5rem" }} className="navbar-imgs">
        <Link to="/">
          <img
            loading="lazy"
            // src={nationalGamesLogo}
            src='/logo/white_updated.png'
            alt="National Games White Logo"
          />
        </Link>
      </div>
    </div>
  );
};

export default NavLogo;
