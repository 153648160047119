import "./GamePartners.css";

const GamePartners = ({ partnersList }) => {
  return (
    <div className="game-partners-main-container">
      <div className="text-center partners-heading_div">PARTNERS</div>
      <div className="text-center partners-heading-txt">
        Discover the partners making it all possible
      </div>
      
      {partnersList?.length > 0 &&
        partnersList?.map((item, index) => (
          <div className="game-partners-container" key={item?.id || index}>
            <div className="game-heading-line">
              <span></span>
              <h5 className="text-center bg-gp-heading fw-bold display-7">
                {item?.heading}
              </h5>
              <span></span>
            </div>

            <div className="text-center mb-4"></div>
            <Partners partners={item?.list} />
          </div>
        ))}
    </div>
  );
};

const Partners = ({ partners }) => {
  return (
    <div className="partners-main">
      {partners?.map((item, index) => (
        <div className="partner-card" key={item?.id || index}>
          <div className="before"></div>
          <img
            loading="lazy"
            src={item?.image}
            alt={item?.value + " " + index}
            className="partner-logo"
            style={{ width: item?.width, height: item?.height }}
          />
          <p className="logo-name">{item?.value}</p>
        </div>
      ))}
    </div>
  );
};

export default GamePartners;
