import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sportToImgIcons } from "./VenueDates";
import greendoticon from '../../assets/home2/Schedule/Ellipse 64.png'
import torch from "../../assets/home2/Schedule/torch 1.png"
import { sportsName } from './Schedulejson';
import styles from "./SchedulePage.module.css";
import { differentSportsResults } from "utils/utils";
import { getReplacedName } from "utils/helper";
import dayjs from "dayjs";



const getPropertyNoCase = (obj, prop) => {
    return obj[
        Object.keys(obj)?.find((key) =>
            prop?.toLowerCase()?.includes(key.toLowerCase())
        )
    ];
};



const SportSchedule = ({ dateArray, startIndex, visibleDates }) => {
    const history = useHistory();
    const handleClickRoute = (item) => {
        if (item?.routing) {
            differentSportsResults?.includes(item?.routing)
                ? window.open(
                    `https://tsr.37nationalgamesgoa.in/scoring-scheduling/sport/${getReplacedName(
                        item?.routing
                    )}`
                )
                :
                history.push(`/sports/${item?.routing}`);
        }
    };
    return (

        // <div>
        //   <div  className="p-1 rounded-2">
        //     <div>
        //       {sportsName?.map((item, id) => {
        //         console.log("sportName===>", item.sportName)
        //         return (

        //           <div
        //             key={id + "-sportTiles"}
        //             className="grid grid-cols-10"
        //             style={{
        //               background: "white",
        //               borderRadius: "10px",
        //               marginBottom: sportsName?.length - 1 !== id && "7px",
        //             }}
        //             onClick={() => handleClickRoute(item)}
        //           >
        //             <div
        //               className={`col-span-2 flex align-items-center gap-2 d-flex ${styles.leftText}`}
        //             >
        //               <img
        //                 loading="lazy"
        //                 src={
        //                   getPropertyNoCase(
        //                     sportToImgIcons,
        //                     item?.key ?? item?.sportName
        //                   ) ?? item?.img
        //                 }
        //                 alt={`Icon for ${item?.sportName}`}
        //                 className={`icon  rounded-2 m-2 ${item.sportName === "Opening & Closing Ceremonies" ? "" : "linergradient"}`}
        //                 style={{
        //                   width: "60px",
        //                   height: "60px",
        //                   objectFit: "contain",
        //                 }}
        //               />
        //               <p
        //                 style={{
        //                   marginBottom: "5px",
        //                   fontSize: "20px",
        //                   textAlign: "start",
        //                 }}
        //               >
        //                 {item?.sportName}
        //               </p>
        //             </div>

        //             <div className="col-span-8 items-center d-flex gap-2 w-100 ">
        //             <div className="col-span-1 items-center d-flex gap-2 w-100 justify-content-between">
        //               {dateArray
        //                 .slice(startIndex, startIndex + visibleDates)
        //                 .map((date, dateId) => {
        //                   const isDateWithinRange =
        //                     dayjs(date, "DD-MMM-YYYY").isAfter(
        //                       dayjs(item.startDate, "DD MMMM YYYY").subtract(
        //                         1,
        //                         "day"
        //                       )
        //                     ) &&
        //                     dayjs(date, "DD-MMM-YYYY").isBefore(
        //                       dayjs(item.endDate, "DD MMMM YYYY").add(1, "day")
        //                     );
        //                   const isStartDate =
        //                     date ===
        //                     dayjs(item?.date?.[0], "DD MMMM YYYY").format(
        //                       "DD-MMMM-YYYY"
        //                     );
        //                   const isEndDate =
        //                     date ===
        //                     dayjs(item.date?.[1], "DD MMMM YYYY").format(
        //                       "DD-MMMM-YYYY"
        //                     );

        //                   const isFirstOrLastDate =
        //                     date !== dayjs().format("DD-MMMM-YYYY") &&
        //                     ((dateId === 0 && isStartDate) ||
        //                       (dateId === visibleDates - 1 && isEndDate));

        //                   return (
        //                     <div
        //                       key={dateId + "-dot"}
        //                       className="w-100 text-center flex justify-content-center align-content-center position-relative"
        //                       style={{
        //                         top: "45%",
        //                       }}
        //                     >
        //                       {isFirstOrLastDate ? (
        //                         <div
        //                           className="font-bold"
        //                           style={{
        //                             width: "16px",
        //                             height: "16px",
        //                             borderRadius: "50%",
        //                             backgroundColor: "#0b9444",
        //                           }}
        //                         ></div>
        //                       ) : null}
        //                       {isDateWithinRange ? (
        //                         <div
        //                           style={{
        //                             width: "16px",
        //                             height: "16px",
        //                             borderRadius: "50%",
        //                             backgroundColor: "#0b9444",
        //                           }}
        //                         ></div>
        //                       ) : null}
        //                     </div>
        //                   );
        //                 })}
        //             </div>
        //             </div>
        //           </div>
        //         )
        //       })}
        //     </div>
        //   </div>
        // </div>


        <Container fluid>
            {sportsName.map((item) => {
                return (
                    <>
                        <Row className=" mb-3">
                            <Col lg={2} className=" text-start">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <img
                                            loading="lazy"
                                            src={
                                                getPropertyNoCase(
                                                    sportToImgIcons,
                                                    item?.key ?? item?.sportName
                                                ) ?? item?.img
                                            }
                                            alt={`Icon for ${item?.sportName}`}
                                            className={`icon  rounded-2 m-2 ${item.sportName === "Opening & Closing Ceremonies" ? "" : "linergradient"}`}
                                            style={{
                                                width: "60px",
                                                height: "60px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <p
                                            style={{
                                                marginBottom: "5px",
                                                fontSize: "20px",
                                                textAlign: "start",
                                            }}
                                        >
                                            {item?.sportName}
                                        </p>
                                    </div>


                                </div>

                            </Col>
                            <Col lg={10} className="d-flex justify-content-center align-items-center">
                                <Row className="w-100 flex-wrap">
                                    {/* {Array.from({ length: 15 }).map((_, index) => (
                                        <Col
                                            key={index}
                                            className="d-flex justify-content-center sport_cube_col"
                                        >

                                            <div className={`${item.id % 2 === 0 ? "sport_cube_box" : "sport_cube_box_white"}`}>
                                               
                                                {index === 0 && item.sportName === "Opening & Closing Ceremonies" ? (
                                                    <img src={torch} alt="Torch Icon" />
                                                ) : (
                                                   
                                                    item.sportName !== "Opening & Closing Ceremonies" && (
                                                        <img src={greendoticon} alt="Green Dot Icon" />
                                                    )
                                                )}
                                            </div>
                                        </Col>
                                    ))} */}

                                    <div className="col-span-8 items-center d-flex gap-2 w-100 ">
                                        <div className="col-span-1 items-center d-flex gap-2 w-100 justify-content-between mb-3 mb-lg-0">
                                            {dateArray
                                                .slice(startIndex, startIndex + visibleDates)
                                                .map((date, dateId) => {
                                                    const isDateWithinRange =
                                                        dayjs(date, "DD-MMM-YYYY").isAfter(
                                                            dayjs(item.startDate, "DD MMMM YYYY").subtract(
                                                                1,
                                                                "day"
                                                            )
                                                        ) &&
                                                        dayjs(date, "DD-MMM-YYYY").isBefore(
                                                            dayjs(item.endDate, "DD MMMM YYYY").add(1, "day")
                                                        );
                                                    const isStartDate =
                                                        date ===
                                                        dayjs(item?.date?.[0], "DD MMMM YYYY").format(
                                                            "DD-MMMM-YYYY"
                                                        );
                                                    const isEndDate =
                                                        date ===
                                                        dayjs(item.date?.[1], "DD MMMM YYYY").format(
                                                            "DD-MMMM-YYYY"
                                                        );

                                                    const isFirstOrLastDate =
                                                        date !== dayjs().format("DD-MMMM-YYYY") &&
                                                        ((dateId === 0 && isStartDate) ||
                                                            (dateId === visibleDates - 1 && isEndDate));

                                                    return (
                                                        <div
                                                            key={dateId + "-dot"}
                                                            className="w-100 text-center flex justify-content-center align-content-center position-relative"
                                                            style={{
                                                                top: "45%",
                                                            }}
                                                        >
                                                            {isFirstOrLastDate ? (
                                                                <div
                                                                    className="font-bold"
                                                                    style={{
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        borderRadius: "50%",
                                                                        backgroundColor: "red",
                                                                    }}
                                                                ></div>
                                                            ) : null}
                                                         

                                                            <Col
                                                               
                                                                className="d-flex justify-content-center sport_cube_col"
                                                            >

                                                                <div className={`${isDateWithinRange ? "sport_cube_box" : "sport_cube_box_white"}`}>

                                                                    {isDateWithinRange && item.sportName === "Opening & Closing Ceremonies" ? (
                                                                        <img src={torch} alt="Torch Icon" />
                                                                    ) : (

                                                                        isDateWithinRange ? (
                                                                            <img src={greendoticon} alt="Green Dot Icon" />
                                                                        ) : null
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>



                                </Row>




                            </Col>
                        </Row>
                    </>
                )
            })}

        </Container>
    );
};

export default SportSchedule;
