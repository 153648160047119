import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import { clusterdatalist } from './Schedulejson';


const ClusterTable = () => {
    return (
        <>
            <Row className="p-5">
                {clusterdatalist.map((item) => {
                    // console.log("data====>",data.clusterSportList)
                    return (
                        <>
                            <Col lg={12} className="cluster_table_container  ">
                                <Table responsive className="table-with-spacing">
                                    <thead>
                                        <tr>
                                            <th className="text-start   px-4" colspan="6"><span className="table_list_heading">{item.clusterHeading}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.clusterSportList.map((data) => {
                                            return (
                                                <>
                                                    <tr className="cluster_listing_Data py-4 " key={data.id}>
                                                        <td><img src={data.sportimg} className="cluster_sport_img"/></td>
                                                        <td>{data.sportName}</td>
                                                        <td>{data.sportplace}</td>
                                                        <td>{data.sportaddress}</td>
                                                        <td>{data.sportstatingData}</td>
                                                        <td>{data.sportendData}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}


                                    </tbody>
                                </Table>
                            </Col>
                        </>
                    )
                })}


            </Row>
        </>
    )
}

export default ClusterTable