
import seal_of_utterakhand from "../../../assets/home2/Body/uk_shashan.png";
import uk_directrate from "assets/gamePartners/sport_2 1uk_sports_directrate.png";
import uk1 from "assets/gamePartners/uk1.png"
import ministry from "assets/gamePartners/Ministry_of_Youth_Affairs_and_Sports 2monistary_of_sport.png";
import IOA from "assets/gamePartners/ioa.png";

import ONGC from "assets/gamePartners/ONGC.png";
import IndianOil from "assets/gamePartners/Indian Oil.png";
import CoalIndia from "assets/gamePartners/Coal_India_Logo.png";


import NLC from "assets/gamePartners/nlc.png";
import Powergrid from "assets/gamePartners/Powergrid.png";
import PFC from "assets/gamePartners/PFC.png";
import FITSUPPORT from "assets/gamePartners/FITSUPPORT.png";

import T10sports from "assets/gamePartners/T10sports.png";
import Paytm from "assets/gamePartners/Paytm.png";
import Spartan from "assets/gamePartners/Spartan.png";
import COSCO from "assets/gamePartners/COSCO.png";

import DD_Sports from "assets/gamePartners/DD_Sports.jpg";
import jiocinema from "assets/gamePartners/jiocinema.png";

import SAIL from "assets/gamePartners/SAIL.png";
import Bisleri from "assets/gamePartners/Bisleri.jpeg";

export const partnersList = [
  {
    heading: "GAMES PARTNERS",
    id:"1",
    list: [
      {
        id:1,
        image: seal_of_utterakhand,
        width: "143px",
        height: "145px",
        value: "GOVERNMENT OF UK",
      },
      {
        id:2,
        image: uk_directrate,
        width: "152px",
        height: "172px",
        value: "Uk directrate",
      },
      {
        id:3,
        image: ministry,
        width: "259px",
        height: "132px",
        value: "MINISTRY OF YOUTH AFFAIRS AND SPORTS GOVERNMENT OF INDIA",
      },
      {
        id:4,
        image: uk1,
        width: "259px",
        height: "132px",
        value: "MINISTRY OF YOUTH AFFAIRS AND SPORTS GOVERNMENT OF INDIA",
      },
      {
        id:5,
        image: IOA,
        width: "133px",
        height: "131px",
        value: "INDIAN OLYMPIC ASSOCIATION",
      },
    ],
  },
  // {
  //   heading: "Supported by",
  //   list:[
  //       {
  //           image: ONGC,
  //           width: "162px",
  //           height: "163px",
  //           value: "ONGC",
  //       },
  //       {
  //           image: IndianOil,
  //           width: "133px",
  //           height: "155px",
  //           value: "Indian oil",
  //       },
  //       {
  //           image: CoalIndia,
  //           width: "98px",
  //           height: "134px",
  //           value: "Coal India",
  //       },
  //   ]
  
  // },
//   {
//     heading: " Associate Sponsors",
//     list:[ {
//         image: NLC,
//         width: "146px",
//         height: "138px",
//         value: "ONGC",
//     },
//     {
//         image: Powergrid,
//         width: "160px",
//         height: "150px",
//         value: "Indian oil",
//     },
//     {
//         image: PFC,
//         width: "148px",
//         height: "156px",
//         value: "Coal India",
//     },
//     {
//         image:FITSUPPORT,
//         width: "282px",
//         height: "62px",
//         value: "Coal India",
//     },
    
// ]
  
//   },

//   {
//     heading: "Collaborators",
//     list:[ {
//         image: T10sports,
//         width: "174px",
//         height: "149px",
//         value: "ONGC",
//     },
//     {
//         image: Paytm,
//         width: "150px",
//         height: "150px",
//         value: "Indian oil",
//     },
//     {
//         image: Spartan,
//         width: "216px",
//         height: "163px",
//         value: "Coal India",
//     },
//     {
//         image:COSCO,
//         width: "222px",
//         height: "118px",
//         value: "Coal India",
//     },
    
// ]
//   },
//   {
//     heading:"Broadcast Partner",
//     list:[
//         {
//             image: DD_Sports,
//             width: "246px",
//             height: "60px",
//             value: "ONGC",
//         },
//         {
//             image: jiocinema,
//             width: "129px",
//             height: "129px",
//             value: "Indian oil",
//         }

//     ]
//   }
];
