import React, { useState, useEffect } from 'react';
import "./styles.scss";

// function LiveTimer({ initialDays, initialHours, initialMinutes, initialSeconds }) {
//   const [days, setDays] = useState(initialDays);
//   const [hours, setHours] = useState(initialHours);
//   const [minutes, setMinutes] = useState(initialMinutes);
//   const [seconds, setSeconds] = useState(initialSeconds);

//   useEffect(() => {
//     const interval = setInterval(() => {
    
//       if (seconds > 0) {
//         setSeconds(seconds - 1);
//       } else {
//         if (minutes > 0) {
//           setMinutes(minutes - 1);
//           setSeconds(59);
//         } else {
//           if (hours > 0) {
//             setHours(hours - 1);
//             setMinutes(59);
//             setSeconds(59);
//           } else {
//             if (days > 0) {
//               setDays(days - 1);
//               setHours(23);
//               setMinutes(59);
//               setSeconds(59);
//             } else {
              
//               clearInterval(interval);
            
//             }
//           }
//         }
//       }
//     }, 1000); // Update timer every second

//     // Clean up interval on component unmount
//     return () => clearInterval(interval);
//   }, [days, hours, minutes, seconds]); 

//   return (
//     <div className='live-timing'>
//       <div className='time-container'>
//         <div className='num-live-time'>{days}:</div>
//         <div className='days'>DAYS</div>
//       </div>
//       <div className='time-container'>
//         <div className='num-live-time'>{hours}:</div>
//         <div className='hours'>HOURS</div>
//       </div>
//       <div className='time-container'>
//         <div className='num-live-time'>{minutes}:</div>
//         <div className='mins'>MINS</div>
//       </div>
//       <div className='time-container'>
//         <div className='num-live-time'>{seconds}</div>
//         <div className='sec'>SEC</div>
//       </div>
//     </div>
//   );
// }
import calculateTimeRemaining from 'components/home2/utterakhand_clock/counter_exp';

function LiveTimer({ initialDays, initialHours, initialMinutes, initialSeconds }) {

  const [time, setTime] = useState(calculateTimeRemaining());
  const [trimmedDays,setTrimmedDays] = useState('00');
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(calculateTimeRemaining);
      let days=   trimKeepLast2(time.days);
      setTrimmedDays(days)
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function trimKeepLast2(str) {
    let trimmedStr = str.replace(/^0+/, '');
      if (trimmedStr.length < 2) {
        // Pad with zeros if the length is less than 2
        trimmedStr = trimmedStr.padStart(2, '0');
    }   
    return trimmedStr;
}
  return (
    <div className='live-timing'>
      <div className='time-container'>
        <div className='num-live-time'>{trimmedDays}:</div>
        <div className='days'>DAYS</div>
      </div>
      <div className='time-container'>
        <div className='num-live-time'>{time?.hour}:</div>
        <div className='hours'>HOURS</div>
      </div>
      <div className='time-container'>
        <div className='num-live-time'>{time?.mins}:</div>
        <div className='mins'>MINS</div>
      </div>
      <div className='time-container'>
        <div className='num-live-time'>{time?.sec}</div>
        <div className='sec'>SEC</div>
      </div>
    </div>
  );
}


export default LiveTimer;
