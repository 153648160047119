import { useState, useEffect } from "react";
import PageTitle from "components/pageTitle/PageTitle";
import TopClock from "../../components/home2/Body/TopClock/Index";
import styles from "./styles.module.css";

const Banner = ({
  text = "Participant Team",
  children,
  img,
  clock = 1,
  pagetitle,
  subPageTitle,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 770);
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={styles.mainContainer}>
      <div>
        <PageTitle motherMenu={pagetitle} activeMenu={subPageTitle} />
        <div className={`${styles.bannerContainer}`}>
          <img
            src={
              isMobile
                ? "/assets/images/bannerResponsive.png"
                : img || "/assets/images/BANNER.png"
            }
            alt="banner"
            className={`${styles.bannerImage}`}
          />
          {text && (
            <h1
              id="participantTeam"
              className={`${styles.participantTeam}`}
              style={{
                maxWidth: "50%",
              }}
            >
              {text}
            </h1>
          )}
          {children}

          {clock && (
            <div
            // style={{
            //   display: "flex",
            //   justifyContent: "space-around",
            //   marginTop: "-2rem",
            //   zIndex: "100",
            // }}
            >
              {/* <TopClock /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
